import React, { useEffect, useState } from 'react'
import {
  SWAN_STYLE_KEY_MAP,
} from '@vp/swan'
import { useSwanStyleKeys } from '@vp/ubik-context'
import { TrackingProvider } from './hooks/TrackingContext'
import { CampaignProvider } from '@vp/campaign-callouts'
import TilesSection from './components/TilesSection'
import { isBrowser } from './utils/shared'

export type AppProps = {
  locale: string,
  tenant: string,
  eagerlyLoad: boolean,
  promoCodeText: string,
  tileRow: any
}

export const Fragment = (props: AppProps) => {
  const [hashExists, setHashExists] = useState(false)
  useEffect(() => {
    setHashExists(isBrowser() && window.location.hash !== '')
  }, [])

  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.utility,
  ])
  if (!props.tileRow) { return <></> }

  return (
    <TrackingProvider canonicalUrl='https://www.vistaprint.com/offers'>
      <CampaignProvider tenant={props.tenant} locale={props.locale}>
        <TilesSection
          tileImageLoadingStrategy={props.eagerlyLoad || hashExists ? 'eager' : 'lazy'}
          leadInTitle={props.tileRow.leadInTitle}
          tileData={props.tileRow.tiles}
          hasBanner={!!props.tileRow.bannerData}
          tileTrackingZone={props.tileRow.tileTrackingZone}
          promoCodeText={props.promoCodeText}
        />
      </CampaignProvider>
    </TrackingProvider>
  )
}
